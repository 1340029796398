import React from 'react';
import AlbumsList from './album/AlbumsList';

function Home() {
  return (
    <>
      <AlbumsList />
    </>
  );
}

export default Home;
